@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dancing-script {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.kalam-light {
  font-family: "Kalam", cursive;
  font-style: normal;
  font-weight: 300;
}

.kalam-regular {
  font-family: "Kalam", cursive;
  font-style: normal;
  font-weight: 400;
}

.kalam-bold {
  font-family: "Kalam", cursive;
  font-style: normal;
  font-weight: 700;
}

.lexend {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 800;
}

.toggle-switch {
  cursor: pointer;
  height: 40px;
  position: relative;
}

.toggle-switch .checkbox-input {
  -webkit-appearance: none;
  background: #eedbd8;
  border: solid 2px #f0ccac;
  border-radius: 20px;
  border-style: outset;
  cursor: pointer;
  height: 40px;
  opacity: 1;
  outline: none;
  position: relative;
  transition: 0.5s;
  width: 80px;
}

.toggle-switch .checkbox-input.bakery {
  background: #eedbd8;
}

.toggle-switch .checkbox-input::before {
  background: linear-gradient(to top, #000, #555);
  border-radius: 20px;
  box-shadow: 0 0 0 1px #232323;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(0.98, 0.96);
  transition: 0.5s;
  width: 40px;
}

.toggle-switch .checkbox-input::after {
  background: #f0ccac;
  border-radius: 50%;
  box-shadow: 0 0 5px #000, 0 0 15px #000, 0 0 30px #000;
  content: "";
  height: 4px;
  left: 18px;
  position: absolute;
  top: calc(50% - 2px);
  transition: 0.5s;
  width: 4px;
}

.toggle-switch .checkbox-input:checked {
  background: #40251b !important;
}

.toggle-switch .checkbox-input.bakery:checked {
  background: #40251b !important;
}

.toggle-switch .checkbox-input:checked::before {
  left: 36px;
}

.toggle-switch .checkbox-input:checked::after {
  background: #f0ccac;
  box-shadow: 0 0 5px #f0ccac, 0 0 15px #f0ccac, 0 0 30px #f0ccac;
  left: calc(14px + 40px);
}

.menu {
  transform: scaleY(0);
  transition: 0.25s ease-in-out;
  transform-origin: top;
}

.menu.active {
  transform: scaleY(1) !important;
}

.text-shadow {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

@media (max-height: 710px) {
  .menu {
    max-height: 500px;
    overflow: auto;
  }
}

.polygon {
  clip-path: polygon(
    0% 12%,
    3% 12%,
    3% 0,
    97% 0,
    97% 12%,
    100% 12%,
    100% 88%,
    97% 88%,
    97% 100%,
    3% 99%,
    3% 88%,
    0% 88%
  );
}
